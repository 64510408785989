
const cropImage = (imageURI, cropConfig, callback) => {
    const getCroppedImage = (uri, cropConfig, callback) => {
        const image = new Image();
        image.src = uri;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const aspectRatio = cropConfig.ratio;
            const screenWidth = window.innerWidth;
            let cropWidth, cropHeight

            if (screenWidth >= 440) {
                cropHeight = aspectRatio === 9 / 6 ? image.height * 0.40 : image.height * 0.75
                cropWidth = cropHeight * aspectRatio
            } else {
                cropWidth = screenWidth * 0.95
                cropHeight = cropWidth / aspectRatio
            }

            const cropX = (image.width - cropWidth) / 2;
            const cropY = (image.height - cropHeight) / 2.1;

            //KTP OCR requires minimum height/width of 256px
            if (cropHeight <= 256 && aspectRatio === 9 / 6) {
                canvas.height = 256
                canvas.width = 256 * aspectRatio
            } else {
                canvas.height = cropHeight
                canvas.width = cropWidth
            }

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, canvas.width, canvas.height);
            callback(canvas.toDataURL("image/jpeg"));
        };
    };

    getCroppedImage(imageURI, cropConfig, (croppedDataUri) => {
        callback(croppedDataUri);
    })
}

export default cropImage;