import { bankBCA, bankBCAC } from "../assets";
import {
  agamaData,
  kewarganegaraanData,
  jumlahPenghasilanData,
  pekerjaanData,
  pembukaanRekeningData,
  pendidikanData,
  pernikahanData,
  sumberPenghasilanData,
  bidangUsahaData,
  jabatanData,
  cityData,
  hubunganData,
  jenisKelaminData,
  agreementData,
  pekerjaanPOTData,
} from "./staticData";

export const registerFormModel = [
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true,
  },
  {
    id: "phoneNumber",
    label: "Nomor Telepon",
    type: "text",
    placeholder: "08xxxxxxxxxx",
    required: true,
  },
];

export const ocrFormModel = [
  {
    id: "idNumber",
    label: "Nomor e-KTP",
    type: "number",
    required: true,
    min: 16,
  },
  {
    id: "name",
    label: "Nama Sesuai e-KTP",
    type: "text",
    required: true,
  },
  {
    id: "gender",
    label: "Jenis Kelamin",
    type: "select",
    required: true,
    menuItem: jenisKelaminData,
  },
  {
    id: "placeOfBirth",
    label: "Tempat Lahir",
    type: "text",
    required: true,
  },
  {
    id: "birthday",
    label: "Tanggal Lahir",
    type: "date",
    required: true,
  },
  {
    id: "npwp",
    label: "NPWP (Opsional)",
    type: "text",
    required: false,
  },
  {
    id: "mothersName",
    label: "Nama Ibu Kandung",
    type: "text",
    required: true,
  },
  {
    id: "nationality",
    label: "Kewarganegaraan",
    type: "select",
    required: true,
    menuItem: kewarganegaraanData,
  },
  {
    id: "religion",
    label: "Agama",
    type: "select",
    required: true,
    menuItem: agamaData,
  },
];

export const idFormModel = [
  {
    id: "address",
    label: "Alamat Sesuai e-KTP",
    type: "text",
    required: true,
  },
  {
    id: "city",
    label: "Kota/Kabupaten",
    type: "search",
    menuItem: cityData,
  },
  {
    id: "postalCodeId",
    label: "Kode Pos",
    type: "text",
    required: true,
    min: 4,
  },
  {
    id: "maritalStatus",
    label: "Status Pernikahan",
    type: "select",
    required: true,
    menuItem: pernikahanData,
  },
  {
    id: "spouseName",
    label: "Nama Pasangan",
    type: "text",
    required: true,
    condition: "maritalStatus",
    conditionValue: 1,
    conditionFunction: function (formProps) {
      return formProps.values[this.condition] === 1;
    },
  },
  {
    id: "lastEducation",
    label: "Pendidikan Terakhir",
    type: "select",
    required: true,
    menuItem: pendidikanData,
  },
];

export const riskFormModel = [
  {
    id: "incomeTotalInYear",
    label: "Total Penghasilan Dalam Setahun",
    type: "select",
    required: true,
    menuItem: jumlahPenghasilanData,
  },
  {
    id: "incomeSource",
    label: "Sumber Penghasilan",
    type: "select",
    required: true,
    menuItem: sumberPenghasilanData,
  },
  {
    id: "investGoal",
    label: "Tujuan Investasi",
    type: "select",
    required: true,
    menuItem: pembukaanRekeningData,
  },
];

export const POTriskFormModel = [
  {
    id: "POTincomeTotalInYear",
    label: "Total Penghasilan Pasangan/Orang tua Dalam Setahun",
    type: "select",
    required: true,
    menuItem: jumlahPenghasilanData,
  },
  {
    id: "incomeSource",
    label: "Sumber Penghasilan Pasangan/Orang tua",
    type: "select",
    required: true,
    menuItem: sumberPenghasilanData,
  },
  {
    id: "investGoal",
    label: "Tujuan Investasi",
    type: "select",
    required: true,
    menuItem: pembukaanRekeningData,
  },
];

export const rdnFormModel = [
  {
    id: "rdnBank",
    label: "Pilih Bank RDN",
    type: "radio",
    required: true,
    vertical: true,
    radioItem: [{ image: [bankBCA, bankBCAC], value: "BCA" }],
  },
  {
    id: "account",
    label: "Nama Pemilik Rekening",
    type: "text",
    required: true,
    info: "Pastikan nama kamu sesuai dengan yang tertera di Bank",
    condition: "rdnBank",
    conditionFunction: function (formProps) {
      return formProps.values[this.condition] === "BCA";
    },
  },
  {
    id: "numberAccount",
    label: "Nomor Rekening",
    type: "text",
    required: true,
    condition: "rdnBank",
    conditionFunction: function (formProps) {
      return formProps.values[this.condition] === "BCA";
    },
  },
  {
    id: "verifBank",
    label:
      "Saya dengan ini memberikan persetujuan kepada PT Henan Putihrai Sekuritas untuk meminta konfirmasi atas data rekening saya tersebut diatas kepada PT Bank Central Asia Tbk.",
    type: "checkbox",
    required: true,
    condition: "rdnBank",
    conditionFunction: function (formProps) {
      return formProps.values[this.condition] === "BCA";
    },
  },
];

const checkValuePot = (value) => {
  return value === 6 || value === 7;
};

const checkValue = (value) => {
  switch (value) {
    case 6:
      break;
    case 7:
      break;
    case "":
      break;
    default:
      return true;
  }
};

export const jobFormModel = [
  {
    id: "jobs",
    label: "Pekerjaan",
    type: "select",
    required: true,
    menuItem: pekerjaanData,
  },
  {
    id: "institution",
    label: "Nama Kantor/Institusi Pendidikan",
    type: "text",
    required: true,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "businessField",
    label: "Bidang Usaha",
    type: "search",
    required: false,
    menuItem: bidangUsahaData,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "position",
    label: "Jabatan",
    type: "select",
    required: false,
    menuItem: jabatanData,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "institutionAddress",
    label: "Alamat Kantor",
    type: "text",
    required: true,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "institutionCity",
    label: "Kota/Kabupaten Kantor",
    type: "search",
    required: false,
    menuItem: cityData,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "postalCode",
    label: "Kode Pos Kantor",
    type: "text",
    required: false,
    condition: "jobs",
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValue(thisCondition);
    },
  },
  {
    id: "namePOT",
    label: "Nama Pasangan/Orang Tua",
    type: "text",
    required: true,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
  {
    id: "relationshipPOT",
    label: "Hubungan dengan Pasangan/Orang Tua",
    type: "select",
    required: true,
    menuItem: hubunganData,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
  {
    id: "jobsPOT",
    label: "Pekerjaan Pasangan/Orang Tua",
    type: "select",
    required: true,
    menuItem: pekerjaanPOTData,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
  {
    id: "businessFieldPOT",
    label: "Bidang Usaha Pasangan/Orang Tua",
    type: "search",
    required: false,
    menuItem: bidangUsahaData,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
  {
    id: "positionPOT",
    label: "Jabatan Pasangan/Orang Tua",
    type: "select",
    required: false,
    menuItem: jabatanData,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
  {
    id: "institutionPOT",
    label: "Nama Kantor Pasangan/Orang Tua",
    type: "text",
    required: true,
    condition: "jobs",
    conditionValue: [6, 7],
    conditionFunction: function (formProps) {
      const thisCondition = formProps.values[this.condition];
      return checkValuePot(thisCondition);
    },
  },
];

export const statementFormModel = [
  {
    id: "bankNpwp",
    label: "Nasabah memiliki/tidak memiliki NPWP (Pilih salah satu)",
    type: "radio",
    required: true,
    vertical: true,
    radioItem: [
      {
        label: "Nasabah memiliki NPWP",
        value: 0,
      },
      {
        label:
          "Nasabah adalah Wajib Pajak yang sesuai dengan ketentuan perpajakan yang berlaku sudah memenuhi persyaratan subjektif dan objektif dan diwajibkan untuk mendaftarkan diri guna mendapatkan NPWP, antara lain memiliki penghasilan di atas Penghasilan Tidak Kena Pajak (PTKP). Sehubungan dengan hal tersebut, Nasabah dengan ini mengikatkan diri untuk segera melakukan pengurusan NPWP dan segera menyerahkan fotokopi NPWP kepada BCA.",
        value: 1,
      },
      {
        label:
          "Nasabah adalah Wajib Pajak yang sesuai dengan ketentuan perpajakan yang berlaku, saat ini tidak/belum memenuhi persyaratan subjektif dan objektif untuk mendapatkan NPWP. Jika di kemudian hari persyaratan tersebut telah dapat dipenuhi, maka Nasabah dengan ini mengikatkan diri untuk segera melakukan pengurusan NPWP dan segera menyerahkan fotokopi NPWP kepada BCA.",
        value: 2,
      },
    ],
  },
  {
    id: "agreement",
    link: agreementData,
    type: "checkbox",
    required: true,
  },
  {
    id: "bankAgreement",
    label:
      "Demikian pernyataan ini dibuat dengan sebenarnya untuk dapat dipergunakan sebagaimana mestinya.",
    type: "checkbox",
    required: true,
  },
];

export const disclaimerFinal = [
  {
    id: "finalDisclaimer",
    label: "Saya setuju",
    type: "checkbox",
    required: true,
  },
];
