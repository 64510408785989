import React, { useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { setAlert } from "../../../redux/slices/alertSlice";

import { PromptCard, PictureContainer } from "../../molecules";
import { CameraPage } from "../../templates";
import { ocrService } from "../../../services";
import {
  apiUrls,
  saveRegData,
  uploadPhoto,
} from "../../../services/apiService";
import SelfieFrame from "../../organisms/cameraComponents/SelfieFrame";
import cropImage from "../../../services/cropImage";

export const KTPWithFaceSection = ({ onClickNext, activeStep }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);
  const [openCamera, setOpenCamera] = useState(true);
  const [selfieUri, setSelfieUri] = useState("");

  const handleUploadSelfie = async () => {
    setIsLoading(true);
    try {
      const resSelfie = await uploadPhoto(
        selfieUri,
        "uploadSwafoto",
        regData.endpointString,
        apiUrls.uploadSelfie
      );
      if (resSelfie?.data?.file_name) {
        dispatch(setRegister({ photoSelfie: resSelfie?.data?.file_name }));
        await saveRegData(
          {
            photoSelfie: resSelfie?.data?.file_name,
            lastSavedSection: activeStep,
          },
          regData.token,
          regData.newEntry
        );
        onClickNext();
      }
    } catch (error) {
      dispatch(setAlert({ message: "Error uploading photo", isOpen: true }));
    } finally {
      setIsLoading(false);
    }
  };

  /* CAMERA PAGE */
  if (openCamera) {
    const handleClick = async (dataUri) => {
      const res = await ocrService.compressImage(dataUri);
      const compressedUri = res.compressedUri;
      const config = { ratio: 5 / 7 };
      cropImage(compressedUri, config, (croppedDataUri) => {
        setSelfieUri(croppedDataUri);
      });
    };

    return (
      <CameraPage
        open={openCamera}
        frame={SelfieFrame}
        facingMode="front"
        onClick={(dataUri) => handleClick(dataUri)}
        onClose={() => setOpenCamera(false)}
      />
    );
  }

  return (
    <Stack
      spacing="32px"
      justifyContent="space-between"
      style={{ height: "100%" }}
    >
      <Stack spacing="36px">
        {/* SECTION TITLE */}
        <PromptCard
          icon={
            <BadgeOutlinedIcon
              style={{ color: "white", width: "40px", height: "40px" }}
            />
          }
          title="Selfie dengan e-KTP"
          subtitle="Data diri kamu yang diwajibkan oleh OJK akan kami jaga kerahasiaannya."
        />

        {/* PHOTO FRAME */}
        <PictureContainer src={selfieUri} aspectRatio={5 / 7} />
      </Stack>

      <Stack direction="row" gap={2} style={{ width: "100%" }}>
        {/* OPEN CAMERA BUTTON */}
        <Button
          fullWidth
          disableElevation
          variant={!selfieUri ? "contained" : "outlined"}
          onClick={() => setOpenCamera(true)}
          // onClick={onClickNext}
          style={{ textTransform: "none" }}
          disabled={isLoading}
        >
          {!selfieUri ? "Ambil foto" : "Ulangi"}
        </Button>

        {/* SUBMIT BUTTON */}
        {selfieUri && (
          <Button
            fullWidth
            type="submit"
            disableElevation
            variant="contained"
            onClick={handleUploadSelfie}
            style={{ textTransform: "none" }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Berikutnya"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

KTPWithFaceSection.propTypes = {
  onClickNext: PropTypes.func,
  activeStep: PropTypes.number,
};
