/* eslint-disable react/no-children-prop */
import TaskIcon from "@mui/icons-material/Task";
import React /*{ useState }*/ from "react";
import {
  Stack,
  Button,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Link,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import { PromptCard } from "../../molecules";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisclaimerData,
  getStatementData,
} from "../../../models/staticData";
import { useFormik } from "formik";
import { setRegister } from "../../../redux/slices/registerSlice";
import { FormTemplate } from "../../templates";
import { disclaimerFinal, statementFormModel } from "../../../models";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import Disclaimer from "../../organisms/disclaimer";
import { FormFields } from "../../organisms";
import { saveRegData } from "../../../services/apiService";

export const StatementFormSection = ({ onClickNext }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [statementData, setStatementData] = React.useState([]);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [disclaimerData, setDisclaimerData] = React.useState({});
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  React.useEffect(() => {
    fetchStatementData();
    getDisclaimerData().then((data) => {
      setDisclaimerData(data[1]);
    });
  }, []);

  const fetchStatementData = async () => {
    const data = await getStatementData(regData.rdnBank);
    setStatementData(data);
    setLoadingData(false);
  };

  const validationSchema = yup.object(yupSchemaGenerator(statementFormModel));

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister(values));
      await saveRegData(values, regData.token, regData.newEntry);
      setOpenDisclaimer(true);
      setIsLoading(true);
      // // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);
    },
  });

  const disclaimerForm = useFormik({
    validationSchema: yup.object(yupSchemaGenerator(disclaimerFinal)),
    initialValues: {
      finalDisclaimer: false,
    },
    onSubmit: (values) => {
      setOpenDisclaimer(false);
      onClickNext();
    },
  });

  return (
    <Stack justifyContent="space-between" style={{ height: "100%" }}>
      <Stack gap={2}>
        {/* SECTION TITLE */}
        <PromptCard
          icon={<TaskIcon style={{ width: "40px", height: "40px" }} />}
          title={`Pernyataan Nasabah RDN ${regData.rdnBank}`}
          subtitle="Informasi lain yang diperlukan pihak bank untuk pembukaan rekening"
        />
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            textAlign: "center",
            mt: 1,
          }}
        >
          {`Pernyataan Nasabah RDN ${regData.rdnBank}`}
        </Typography>
        <Divider />
        {loadingData ? (
          <Stack alignItems="center" justifyContent="center" height="50vh">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            gap="5px"
            maxHeight="45vh"
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Typography style={{ fontWeight: 300, fontSize: "14px" }}>
              Dengan ini Nasabah menyatakan:
            </Typography>
            <List
              sx={{
                mx: "8px",
                listStyleType: "decimal",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              {statementData?.items?.list?.map((item, index) => (
                <ListItem
                  key={index}
                  style={{
                    fontWeight: 300,
                    fontSize: "14px",
                    margin: "0 16px 16px",
                    padding: "0 16px",
                  }}
                >
                  {!item?.item ? (
                    item
                  ) : (
                    <>
                      {item?.item}
                      <List
                        sx={{
                          listStyleType: "lower-alpha",
                        }}
                      >
                        {item?.list?.map((item, index) => (
                          <ListItem key={index}>
                            {!item?.item ? (
                              item
                            ) : (
                              <>
                                {item?.item}
                                <List
                                  sx={{
                                    listStyleType: "circle",
                                  }}
                                >
                                  {item?.list?.map((item, index) => (
                                    <ListItem key={index}>{item}</ListItem>
                                  ))}
                                </List>
                              </>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
        {statementData.file && statementData.url_text && (
          <Link
            href={statementData.file}
            target="_blank"
            rel="noopener noreferrer"
            fontSize={14}
          >
            {statementData.url_text}
          </Link>
        )}
        <Divider />
        <Stack mt={1}>
          <FormTemplate
            formProps={formProps}
            isLoading={isLoading}
            formModel={statementFormModel}
            buttonText="Berikutnya"
            submitFunction={() => {
              formProps.handleSubmit;
              setOpenDisclaimer(true);
            }}
          />
          <Disclaimer
            data={disclaimerData}
            open={openDisclaimer}
            buttonFunction={disclaimerForm.handleSubmit}
            handleClose={() => setOpenDisclaimer(false)}
            ableToClose
            buttonText={"Selesai"}
          >
            <Stack mt={2}>
              <FormFields
                formProps={disclaimerForm}
                formItems={disclaimerFinal}
              />
            </Stack>
          </Disclaimer>
        </Stack>
      </Stack>
    </Stack>
  );
};

StatementFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
