import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { registerFormModel } from "../../../models";
import { keyIcon, keyIconC } from "../../../assets";
import { setRegister } from "../../../redux/slices/registerSlice";
import { FormTemplate } from "../../templates";

import * as yup from "yup";
import "yup-phone";
import YupPassword from "yup-password";
import {
  addEndpoint,
  api,
  apiUrls,
  checkRegData,
} from "../../../services/apiService";
import { setAlert } from "../../../redux/slices/alertSlice";
import { useTheme } from "@mui/material";
import supabase from "../../../services/supabase";
YupPassword(yup);

export const RegFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const validateData = async (values) => {
    try {
      const resCheckReg = await checkRegData({
        email: values.email,
        phoneNumber: values.phoneNumber,
      });

      const regDataBody = resCheckReg.data;
      const savedRegData = regDataBody.data;
      const token = regDataBody.accessToken;
      const endpointString = savedRegData.id.toString().split("-")[0];

      dispatch(
        setRegister({
          ...values,
          token: token,
          endpointString: endpointString, // endpoint string for oaListing API is limited to 30 characters
          newEntry: regDataBody.newEntry,
        })
      );

      if (!regDataBody.newEntry) {
        //condition if saved user registration data exists
        const signIn = await supabase.auth.signInWithOtp({
          email: savedRegData.email,
          options: {
            emailRedirectTo:
              process.env.REACT_APP_SITE_URL +
              "/register" +
              "?email=" +
              savedRegData.email,
            data: {
              id: savedRegData.id,
            },
          },
        });

        if (signIn.error) {
          throw new Error(signIn.error.message);
        } else {
          dispatch(
            setAlert({
              message: "Registrasi ditemukan. Mohon cek email anda",
              severity: "success",
              isOpen: true,
            })
          );
        }
      } else {
        const response = await api.post(
          apiUrls.validateData + addEndpoint([values.email, values.phoneNumber])
        );
        if (response.data.status) {
          sendOtp(values);
        } else {
          dispatch(
            setAlert({
              message: response?.data["error_string"],
              isOpen: true,
            })
          );
          if (response?.data["error_string"][0] === "Email sudah ada") {
            throw new Error("Email sudah ada");
          }
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const sendOtp = async (values) => {
    try {
      const response = await api.post(
        apiUrls.sendOtp + addEndpoint(["", values.phoneNumber, values.email])
      );
      if (response.data.status) {
        setIsLoading(false);
        onClickNext();
      } else {
        dispatch(
          setAlert({
            message: response?.data["message"],
            isOpen: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Masukkan email yang valid")
      .required("Email wajib diisi"),
    phoneNumber: yup
      .string()
      .phone("ID", true, "Nomor telepon tidak valid")
      .matches(/^[0-9]+$/, "Nomor telepon hanya boleh angka")
      .min(10, "Nomor telepon tidak valid")
      .required("Nomor telepon wajib diisi"),
  });

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      setIsLoading(true);
      validateData(values);
    },
  });

  return (
    <FormTemplate
      formProps={formProps}
      icon={theme.palette.mode === "dark" ? keyIcon : keyIconC}
      isLoading={isLoading}
      formModel={registerFormModel}
      title="Buat Akun Kamu"
      buttonText="Berikutnya"
    />
  );
};

RegFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
