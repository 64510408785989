import React, { useState } from "react";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { ocrFormModel } from "../../../models";
import { FormTemplate } from "../../templates";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { saveRegData } from "../../../services/apiService";

export const OcrFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object(yupSchemaGenerator(ocrFormModel));

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        account: values.name,
      };
      dispatch(setRegister(payload));
      await saveRegData(payload, regData.token, regData.newEntry);

      setIsLoading(true);
      // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);

      onClickNext();
    },
  });

  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={ocrFormModel}
      badge={<BadgeOutlinedIcon style={{ width: "40px", height: "40px" }} />}
      title="Informasi dan Identitas Kamu"
      subtitle="Data tentang diri kamu yang diwajibkan oleh OJK yang akan kami lindungi kerahasiaannya."
      buttonText="Berikutnya"
    />
  );
};

OcrFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
