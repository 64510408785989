import React from "react";
import { Box, IconButton, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export const Navbar = ({ progress, OnBack, pageNow, label }) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode == "dark"

  return (
    <Box>
      <Stack
        alignItems={"center"}
        justifyContent="space-between"
        direction={"row"}
        padding={"12px 24px 8px 24px"}
        sx={{
          color: !darkMode && "secondary.main"
        }}
      >
        <ArrowBackIosNewIcon
          onClick={() => OnBack(pageNow == 0 ? 0 : pageNow - 1)}
          style={{ fontSize: "20px", width: "40px", cursor: "pointer" }}
        />
        <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 600 }}>
          {label}
        </Typography>
        <IconButton
          onClick={() => {
            window.open("http://wa.me/628882525888?text=Hi%20Henan%20Putihrai%20Sekuritas!%20Saya%20mengalami%20kendala%20pada%20saat%20registrasi", "_blank");
          }}
        >
          <SupportAgentIcon sx={{ fontSize: "24px", color: !darkMode && "secondary.main" }} />
        </IconButton>
      </Stack>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

Navbar.propTypes = {
  progress: PropTypes.number,
  OnBack: PropTypes.func,
  pageNow: PropTypes.number,
  label: PropTypes.string,
};
