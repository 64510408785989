import axios from "axios";
import { ocrService } from "./ocrService";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiUrls = {
  validateData: "/validateAccountInformation/",
  sendOtp: "/checkNIK/",
  validateOtp: "/validateOTP/",
  uploadKtp: "/pemrek/uploadKTP/",
  uploadSelfie: "/pemrek/uploadSwafoto/",
  uploadSignature: "/pemrek/uploadSignature/",
  saveAll: "/pemrek/saveData/",
};

export const addEndpoint = (valuesString) => {
  return window.btoa(valuesString.join(";"));
};

export const uploadPhoto = async (uri, key, string, endpoint) => {
  const { compressedBlob } = await ocrService.compressImage(uri);
  const file = new File([compressedBlob], "compressed.jpg", {
    type: "image/jpg",
  });
  const formData = new FormData();
  formData.append(key, file);

  const data = await api.post(endpoint + string, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

export const checkRegData = async (data) => {
  const res = await axios.post(
    process.env.REACT_APP_ROW_API_URL + "/check-reg",
    data
  );
  return res;
};

export const saveRegData = async (data, token, newEntry) => {
  const res = await axios.post(
    process.env.REACT_APP_ROW_API_URL + "/save-reg",
    {
      data: data,
      newEntry: newEntry,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const saveAllData = (data) => {
  const formattedData = {
    typeFPRE: 1,
    Ind_Email: data.email,
    Ind_NomorHP: data.phoneNumber,
    kode_sales: data.salesData,
    Ind_NPWP: data.npwp,
    isNPWP: data.npwp ? 1 : 0,
    NamaNasabah: data.name,
    Ind_NomorId: data.idNumber,
    row: 2,
    Ind_JenisId: 1,
    Ind_JenisKelamin: data.gender,
    NamaIbuKandung: data.mothersName,
    Ind_Perkawinan: data.maritalStatus,
    Ind_SumberUtamaDana: data.incomeSource,
    Ind_SumberUtamaDanaLainnya: "",
    Ind_Pekerjaan: data.jobs,
    Ind_Bekerjapadaperusahaan: data.institution,
    Ind_BidangUsaha: data.businessField,
    Ind_Jabatan: data.position,
    Ind_Kantor_alamat1: data.institutionAddress,
    Ind_Kantor_kota: data.institutionCity,
    Ind_Kantor_kodepos: data.postalCode,
    Ind_Kantor_negara: "Indonesia",
    Ind_Kantor_telepon: "",
    Ind_TujuanInvestasi1: data.investGoal,
    Ind_TujuanInvestasi1_Lainnya: "",
    Ind_PenghasilanTahun: data.incomeTotalInYear,
    Ind_InstruksiAS: data.instruksiAS,
    Ind_Id_alamat1: data.address,
    Ind_TempatLahir: data.placeOfBirth,
    Ind_TglLahir: data.birthday,
    Ind_Id_Kota: data.city,
    Ind_Id_kodePos: data.postalCodeId,
    Ind_tinggal_alamat1: data.address,
    Ind_tinggal_kota: data.city,
    Ind_tinggal_kodepos: data.postalCodeId,
    Ind_AlamatKorespondensi: data.address,
    Ind_WargaNegara: data.nationality,
    Ind_Agama: data.religion,
    Ind_Pendidikan: data.lastEducation,
    bank_rdn: data.rdnBank === "BCA" ? "B009" : "",
    Ind_NamaBank: data.rdnBank,
    Ind_NamaPemilikRekening: data.account,
    Ind_NomorRekening: data.numberAccount,
    rdn_bca: data.rdnBank === "BCA" ? 1 : 0,
    no_acc_bca: data.numberAccount,
    rdn_bca_npwp: data.bankNpwp === "0" ? "" : data.bankNpwp,
    rdn_bca_agreement: data.bankAgreement ? 1 : 0,
    Ind_BekerjaPadaEfek: data.haveFamilyInStockExchange,
    rekening_efek_lain: data.accountExceptMainBank,
    rekening_sid: "",
    Ind_BeneficialOwner: "1",
    Ind_BeneficialOwner_Nama: data.spouseName,
    ktp_path: data.photoKTP,
    swafoto_path: data.photoSelfie,
    signature_path: data.customerSign,
    Ind_NamaLengkapPOT: data.namePOT,
    Ind_HubunganPOT: data.relationshipPOT,
    Ind_PekerjaanPOT: data.jobsPOT,
    Ind_BidangUsahaPOT: data.businessFieldPOT,
    Ind_JabatanPOT: data.positionPOT,
    Ind_KantorPOT: data.institutionPOT,
    Ind_PenghasilanTahunPOT: data.POTincomeTotalInYear,
  };

  return formattedData;
};
