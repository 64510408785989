import React from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import { SectionTitle, PromptCard } from "../molecules";
import { FormFields } from "../organisms";
import PropTypes from "prop-types";

export const FormTemplate = ({
  formProps,
  icon,
  isLoading,
  formModel,
  badge,
  title,
  subtitle,
  buttonText,
}) => {
  return (
    <Stack
      onSubmit={formProps?.handleSubmit}
      component="form"
      spacing="32px"
      justifyContent="space-between"
      style={{ flexGrow: 1, height: "100%" }}
    >
      <Stack spacing="32px">
        {/* SECTION TITLE */}
        {!subtitle && title && <SectionTitle image={icon} title={title} />}
        {subtitle && (
          <PromptCard icon={badge} title={title} subtitle={subtitle} />
        )}

        {/* FORM FIELDS */}
        <FormFields formProps={formProps} formItems={formModel} />
      </Stack>

      {/* SUBMIT BUTTON */}
      <Button
        type="submit"
        variant="contained"
        disableElevation
        style={{ textTransform: "none" }}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : buttonText}
      </Button>
    </Stack>
  );
};

FormTemplate.propTypes = {
  formProps: PropTypes.object,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  formModel: PropTypes.array,
  badge: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
};
