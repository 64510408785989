import {
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  alpha,
  useTheme
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const PromptCard = ({ icon, title, subtitle }) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode == "dark"
  const textColor = darkMode ? "white" : "secondary.main"

  return (
    <Card sx={{ backgroundColor: darkMode ? "#515151" : alpha(theme.palette.primary.main, 0.2) }} elevation={0}>
      <ListItem>
        {icon && (
          <ListItemAvatar>
            <Stack
              alignItems={"left"}
              sx={{ color: textColor }}
            >
              {icon}
            </Stack>
          </ListItemAvatar>
        )}

        <ListItemText
          primary={title}
          secondary={subtitle}
          primaryTypographyProps={{
            color: textColor,
            fontWeight: 700,
            fontSize: "16px",
          }}
          secondaryTypographyProps={{
            fontWeight: 400,
            fontSize: "12px",
          }}
        />
      </ListItem>
    </Card>
  );
};

PromptCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
