import React, { useState } from "react";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { SectionTitle } from "../../molecules";
import { phoneIcon, phoneIconC } from "../../../assets";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  addEndpoint,
  api,
  apiUrls,
  saveRegData,
} from "../../../services/apiService";
import { setAlert } from "../../../redux/slices/alertSlice";

export const OTPSection = ({ onClickNext, activeStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);
  const theme = useTheme();

  const validateChar = (value) => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    return alphanumericRegex.test(value);
  };

  const validateOtp = async (value) => {
    try {
      const response = await api.post(
        apiUrls.validateOtp +
          addEndpoint([
            regData.name,
            regData.phoneNumber,
            regData.email,
            regData.endpointString,
            value,
          ])
      );
      if (response.data.status) {
        setIsLoading(false);
        await saveRegData(
          {
            ...regData, //save form field default values
            lastSavedSection: activeStep,
          },
          regData.token,
          regData.newEntry
        );
        onClickNext();
      } else {
        dispatch(
          setAlert({
            message: "OTP Salah",
            isOpen: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.messasge,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    console.log(values);

    setIsLoading(true);
    await validateOtp(values);
  };

  return (
    <Stack
      spacing="32px"
      justifyContent="space-between"
      style={{ height: "100%" }}
    >
      <Stack spacing="32px">
        {/* SECTION TITLE */}
        <SectionTitle
          image={theme.palette.mode === "dark" ? phoneIcon : phoneIconC}
          title="Masukkan Kode Verifikasi"
          subtitle={`Kode verifikasi telah dikirim ke ${regData.email}`}
        />

        {/* OTP INPUT */}
        <MuiOtpInput
          display="flex"
          length={5}
          value={otp}
          onChange={(newValue) => setOtp(newValue.toUpperCase())}
          validateChar={validateChar}
          onComplete={handleSubmit}
        />

        {/* RE-SEND OTP */}
        <Stack direction="row" gap="4px" flexWrap="wrap">
          <Typography style={{ fontWeight: 400, fontSize: "12px" }}>
            Tidak menerima kode verifikasi?
          </Typography>
          <Typography
            color="primary"
            style={{ fontWeight: 700, fontSize: "12px" }}
          >
            Kirim ulang
          </Typography>
        </Stack>
      </Stack>

      {/* LOADING ANIMATION */}
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Stack>
  );
};

OTPSection.propTypes = {
  onClickNext: PropTypes.func,
  activeStep: PropTypes.number,
};
