import * as yup from "yup";

export const yupSchemaGenerator = (formModel) =>
    formModel.reduce((obj, item) =>
        Object.assign(obj, {
            ...(item.required && (item.type === "checkbox" ?
                { [item.id]: yup.boolean().oneOf([true], 'Wajib disetujui') }
                :
                { [item.id]: yup.string().required(item.type === "radio" ? "Pilih salah satu" : `${item.label} wajib diisi`) })),
            ...(item.min ? { [item.id]: yup.string().min(item.min, `${item.label} minimal ${item.min} karakter`).required(`${item.label} wajib diisi`) } : {}),
        }), {})
