import React, { useEffect, useState } from "react";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { FormTemplate } from "../../templates";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { idFormModel } from "../../../models";
import { saveRegData } from "../../../services/apiService";

export const IdFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);
  const [maritalData, setMaritalData] = useState("");

  const validationSchema = yup.object(
    yupSchemaGenerator(
      idFormModel.filter((item) => {
        if (item.condition) {
          if (maritalData) {
            if (maritalData === 1) {
              return (
                item.conditionValue !== undefined &&
                maritalData === item.conditionValue
              );
            } else {
              return !item.conditionValue;
            }
          }
        } else {
          return true;
        }
      })
    )
  );

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister({ ...values }));
      await saveRegData(values, regData.token, regData.newEntry);
      setIsLoading(true);
      // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);

      onClickNext();
    },
  });

  useEffect(() => {
    setMaritalData(formProps.values.maritalStatus);
  }, [formProps.values.maritalStatus]);

  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={idFormModel}
      badge={<BadgeOutlinedIcon style={{ width: "40px", height: "40px" }} />}
      title="Informasi dan Identitas Kamu"
      subtitle="Data tentang diri kamu yang diwajibkan oleh OJK yang akan kami lindungi kerahasiaannya."
      buttonText="Berikutnya"
    />
  );
};

IdFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
