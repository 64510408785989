import React from "react";
import "./idCardFrame.css";

const IdCardFrame = () => {
  return (
    <div className="idCardFrame-container">
      <div className="idCardFrame-flexbox">
        <div className="idCardFrame-text">Pastikan e-KTP kamu terlihat dengan jelas.</div>
        <div className="idCardFrame-frame" />
      </div>
    </div>
  );
};

export default IdCardFrame;
