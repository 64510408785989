import React from "react";
import "./selfieFrame.css";

const SelfieFrame = () => {
  return (
    <div className="selfieFrame-container">
      <div className="selfieFrame-text">Pastikan wajah dan E-KTP kamu terlihat jelas</div>
      <div className="selfieFrame-flexbox">
        <div className="selfieFrameFace-frame" />
        <div className="selfieFrameKtp-frame">
          <div className="selfieFrame-text">E-KTP</div>
        </div>
      </div>
    </div>
  );
};

export default SelfieFrame;
