import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SelectSearchForm = ({ item, props, formProps }) => {
  const [menuItems, setMenuItems] = React.useState([]);
  const { value, onChange, onKeyDown, ...fieldProps } = props;

  React.useEffect(() => {
    item?.menuItem?.then((menus) => {
      const items = menus.map((menu) => menu.string || menu.kota);
      setMenuItems(items);
    });
  }, []);

  return (
    <>
      <Autocomplete
        key={item?.id}
        isOptionEqualToValue={(option, value) => option === value}
        value={formProps.values?.[item?.id]}
        onChange={(event, value) => {
          formProps.setFieldValue(item?.id, value);
        }}
        options={menuItems}
        renderInput={(params) => <TextField {...params} {...fieldProps} />}
      />
    </>
  );
};

SelectSearchForm.propTypes = {
  item: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};

export default SelectSearchForm;
