import React, { useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { FormTemplate } from "../../templates";
import { rdnFormModel } from "../../../models";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { saveRegData } from "../../../services/apiService";

export const RdnFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object(yupSchemaGenerator(rdnFormModel));

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister(values));
      await saveRegData(values, regData.token, regData.newEntry);

      // setIsLoading(true);
      // // TODO: SUBMIT DATA TO BACKEND
      // setIsLoading(false);

      onClickNext();
    },
  });
  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={rdnFormModel}
      badge={<AccountBalanceIcon style={{ width: "40px", height: "40px" }} />}
      title="Pembukaan RDN"
      subtitle="Pilih bank yang kamu inginkan sebagai rekening dana nasabah"
      buttonText="Berikutnya"
    />
  );
};

RdnFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
