import {
  Alert,
  CssBaseline,
  Snackbar,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { RegisterPage } from "./components";
import { setAlert } from "./redux/slices/alertSlice";
import theme from "./theme";

export default function App() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { primary, secondary, error } = theme.palette;
  const themePalette = {
    primary: prefersDarkMode ? primary : { main: "#088ADB" },
    secondary,
    error,
  };

  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider
        theme={(outerTheme) =>
          createTheme(
            outerTheme,
            createTheme({
              ...outerTheme,
              palette: {
                ...themePalette,
                mode: prefersDarkMode ? "dark" : "light",
              },
              typography: {
                ...outerTheme.typography,
                h3: {
                  color: !prefersDarkMode && secondary.main,
                  fontSize: "24px",
                  fontWeight: 600,
                },
              },
              components: {
                MuiButton: {
                  defaultProps: {
                    color: prefersDarkMode ? "primary" : "secondary",
                  },
                },
                MuiCssBaseline: {
                  styleOverrides: {
                    body: {
                      backgroundColor: prefersDarkMode ? "#000000" : "#E5E5E5",
                    },
                  },
                },
              },
            })
          )
        }
      >
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/register" />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register/:code" element={<RegisterPage />} />
          </Routes>
        </Router>

        {/* ALERT MESSAGE */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={alert?.isOpen}
          onClose={() => dispatch(setAlert({ isOpen: false }))}
        >
          <Alert
            severity={
              alert.severity
                ? alert.severity
                : alert?.message != "Success"
                ? "error"
                : "success"
            }
            onClose={() => dispatch(setAlert({ isOpen: false }))}
          >
            {alert?.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </ThemeProvider>
  );
}
