import { Box } from "@mui/material";
import React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PropTypes from "prop-types";

export const PictureContainer = ({ src, aspectRatio = 9 / 5 }) => {
  return (
    <Box
      style={{
        aspectRatio,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#515151",
      }}
    >
      {!src ? (
        <CameraAltIcon fontSize="large" style={{ color: "white" }} />
      ) : (
        <img
          src={src}
          alt={src}
          style={{
            width: "100%",
            aspectRatio,
            objectFit: "cover",
          }}
        />
      )}
    </Box>
  );
};

PictureContainer.propTypes = {
  src: PropTypes.string,
  aspectRatio: PropTypes.number,
};
