import AddCommentIcon from "@mui/icons-material/AddComment";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { FormTemplate } from "../../templates";
import { getRdnQuestionData } from "../../../models/staticData";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { saveRegData } from "../../../services/apiService";

export const AdditionalFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([])

  const validationSchema = yup.object(yupSchemaGenerator(questions));

  React.useEffect(() => {
    getFields()
  }, [])

  const getFields = async () => {
    const questionsData = await getRdnQuestionData(regData.rdnBank)
    setQuestions(questionsData?.questions)
  }

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister(values));
      await saveRegData(values, regData.token, regData.newEntry);

      setIsLoading(true);
      // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);

      onClickNext();
    },
  });

  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={questions}
      badge={
        <AddCommentIcon
          style={{ width: "40px", height: "40px" }}
        />
      }
      title="Data Tambahan"
      subtitle="Informasi lain yang diperlukan pihak bank untuk pembukaan rekening"
      buttonText="Berikutnya"
    />
  );
};

AdditionalFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
