import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { jobFormModel } from "../../../models";
import { FormTemplate } from "../../templates";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { saveRegData } from "../../../services/apiService";

export const JobFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);
  const [jobData, setJobData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const yupObject = yupSchemaGenerator(
    jobFormModel.filter((item) => {
      if (item.condition) {
        if (jobData) {
          if (jobData === 6 || jobData === 7) {
            return (
              item.conditionValue !== undefined &&
              item.conditionValue.includes(jobData)
            );
          } else {
            return !item.conditionValue;
          }
        }
      } else {
        return true;
      }
    })
  );
  const validationSchema = yup.object(yupObject);

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister(values));
      await saveRegData(values, regData.token, regData.newEntry);
      setIsLoading(true);
      // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);
      onClickNext();
    },
  });
  console.log(formProps.values);

  useEffect(() => {
    setJobData(formProps.values.jobs);
  }, [formProps.values.jobs]);

  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={jobFormModel}
      badge={
        <HomeRepairServiceIcon style={{ width: "40px", height: "40px" }} />
      }
      title="Data Pekerjaan"
      subtitle="Pihak bank memerlukan data pekerjaan kamu untuk pembukaan rekening."
      buttonText="Berikutnya"
    />
  );
};

JobFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
