import React, { useState } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { setRegister } from "../../../redux/slices/registerSlice";
import { setAlert } from "../../../redux/slices/alertSlice";

import { PromptCard, PictureContainer } from "../../molecules";
import { CameraPage } from "../../templates";
import { ocrService } from "../../../services";
import {
  apiUrls,
  saveRegData,
  uploadPhoto,
} from "../../../services/apiService";
import IdCardFrame from "../../organisms/cameraComponents/IdCardFrame";
import compareArea from "../../../services/compareArea";
import cropImage from "../../../services/cropImage";

export const KTPSection = ({ onClickNext, activeStep }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);
  const [openCamera, setOpenCamera] = useState(true);
  const [photoUri, setPhotoUri] = useState("");

  const uploadKTP = async () => {
    try {
      const resKtp = await uploadPhoto(
        photoUri,
        "uploadKTP",
        regData.endpointString,
        apiUrls.uploadKtp
      );
      if (resKtp.data.file_name) {
        dispatch(setRegister({ photoKTP: resKtp?.data?.file_name }));
        await saveRegData(
          {
            photoKTP: resKtp?.data?.file_name,
            lastSavedSection: activeStep,
          },
          regData.token,
          regData.newEntry
        );
        handleOCRRequest();
      }
    } catch (error) {
      dispatch(
        setAlert({ message: "Error uploading KTP photo", isOpen: true })
      );
    }
  };

  const handleOCRRequest = async () => {
    try {
      const response = await ocrService.postIdCardImg(photoUri);
      if (response.statusCode === 200 && response.data) {
        const birthPlaceBirthday = ocrService.splitBirthPlaceBirthday(
          response?.data
        );
        const compareAddress = await compareArea(response.data);
        const combinedAddress = ocrService.combineAddress(
          response?.data,
          compareAddress
        );
        const processedData = {
          ...birthPlaceBirthday,
          ...combinedAddress,
          ...compareAddress,
        };
        const staticGender = await ocrService.stringToValue(
          response?.data?.gender
        );
        const staticMarital = await ocrService.stringToValue(
          response?.data?.maritalStatus
        );
        const staticReligion = await ocrService.stringToValue(
          response?.data?.religion
        );
        const staticNationality = await ocrService.stringToValue(
          response?.data?.nationality
        );

        const payload = {
          ...processedData,
          gender: staticGender,
          maritalStatus: staticMarital,
          religion: staticReligion,
          nationality: staticNationality,
        };

        dispatch(setRegister(payload));
        await saveRegData(payload, regData.token, regData.newEntry);
        onClickNext();
      } else {
        dispatch(
          setAlert({ message: response?.message || response, isOpen: true })
        );
      }
    } catch (error) {
      dispatch(setAlert({ message: error, isOpen: true }));
    } finally {
      onClickNext();
    }
  };

  const handleSubmitKTP = async () => {
    setIsLoading(true);
    await uploadKTP();
    setIsLoading(false);
  };

  const makeSureKTP = [
    "e-KTP sesuai dengan identitas kamu",
    "e-KTP tidak silau dan tidak buram",
    "e-KTP terbaca jelas dan tidak terpotong",
    "Tidak ada objek lain selain e-KTP dalam foto",
  ];

  /* CAMERA PAGE */
  const handleClick = async (dataUri) => {
    const res = await ocrService.compressImage(dataUri);
    const compressedUri = res.compressedUri;
    const config = { ratio: 9 / 6 };
    cropImage(compressedUri, config, (croppedDataUri) => {
      setPhotoUri(croppedDataUri);
    });
  };

  if (openCamera) {
    return (
      <CameraPage
        open={openCamera}
        facingMode="rear"
        frame={IdCardFrame}
        onClick={(dataUri) => handleClick(dataUri)}
        onClose={() => setOpenCamera(false)}
      />
    );
  }

  return (
    <Stack
      spacing="32px"
      justifyContent="space-between"
      style={{ height: "100%" }}
    >
      <Stack spacing="90px">
        {/* SECTION TITLE */}
        <PromptCard
          icon={
            <BadgeOutlinedIcon
              style={{ color: "white", width: "40px", height: "40px" }}
            />
          }
          title={photoUri ? "Informasi dan Identitas Kamu" : "Upload foto KTP"}
          subtitle={
            photoUri
              ? "Data diri kamu yang diwajibkan oleh OJK akan kami jaga kerahasiaannya."
              : "KTP diwajibkan untuk pembukaan rekening saham atas peraturan OJK."
          }
        />
        {/* PHOTO FRAME */}
        <PictureContainer src={photoUri} />
        {photoUri && !openCamera && (
          <Stack
            spacing="20px"
            padding="28px"
            border={"1.5px solid #3A3A3A"}
            borderRadius="10px"
            style={{ marginBottom: "114px" }}
          >
            <Typography fontSize={"24px"} textAlign="center" fontWeight={600}>
              Yuk pastikan dulu hasil foto KTP kamu
            </Typography>
            {makeSureKTP.map((item, id) => (
              <Stack
                direction={"row"}
                key={id}
                alignItems="center"
                spacing="17px"
              >
                <CheckCircleIcon sx={{ color: "#0096C4", fontSize: "20px" }} />
                <Typography fontSize={"14px"} fontWeight={500}>
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>

      <Stack direction="row" gap={2} style={{ width: "100%" }}>
        {/* OPEN CAMERA BUTTON */}
        <Button
          fullWidth
          disableElevation
          variant={!photoUri ? "contained" : "outlined"}
          onClick={() => setOpenCamera(true)}
          style={{ textTransform: "none" }}
          disabled={isLoading}
        >
          {!photoUri ? "Ambil foto" : "Ulangi"}
        </Button>

        {/* SUBMIT BUTTON */}
        {photoUri && (
          <Button
            fullWidth
            type="submit"
            disableElevation
            variant="contained"
            onClick={handleSubmitKTP}
            style={{ textTransform: "none" }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Berikutnya"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

KTPSection.propTypes = {
  activeStep: PropTypes.number,
  onClickNext: PropTypes.func,
};
