import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  FormControlLabel,
  alpha,
  FormHelperText,
  useTheme
} from "@mui/material";

export const SelectForm = ({ item, formProps }) => {
  const [checkedItem, setCheckedItem] = React.useState("")
  const theme = useTheme()
  const darkMode = theme.palette.mode == "dark"

  React.useEffect(() => {
    setCheckedItem(formProps?.values?.[item.id])
  }, [formProps?.values?.[item.id]],)

  return (
    <Stack gap={1}>
      <FormLabel id={item?.id} style={{ fontSize: "14px" }}>
        {item?.label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={item?.id}
        name={item?.id}
        value={formProps?.values?.[item?.id]}
        onChange={formProps?.handleChange}
        defaultValue={item?.radioItem[0]?.value}
        sx={{
          gap: "15px",
          justifyContent:
            item?.radioItem[0]?.image !== undefined ? "center" : "left",
        }}
        row={!item?.vertical}
      >
        {item?.radioItem?.map((radio) => (
          <FormControlLabel
            key={radio?.value}
            value={radio?.value}
            control={
              <Radio
                style={{
                  width: radio?.image !== undefined ? "0" : "fit-content",
                  height: radio?.image !== undefined ? "0" : "fit-content",
                  opacity: radio?.image !== undefined ? "0" : "1",
                }}

              />
            }
            label={
              <Stack alignItems="center">
                {radio?.image !== undefined ? (
                  <img
                    src={darkMode ? radio?.image[0] : radio?.image[1]}
                    alt={radio?.value}
                    style={{ maxWidth: "80px", marginLeft: "-18px" }}
                  />
                ) : (
                  <Typography
                    variant={item?.vertical ? "body2" : null}
                  >
                    {radio?.label}
                  </Typography>
                )}
              </Stack>
            }
            sx={(theme) => ({
              alignItems: 'flex-start',
              border: radio?.image !== undefined ? "1px solid #515151" : "none",
              borderColor: checkedItem === radio.value ? "primary.main" : (darkMode ? "#515151" : "#E0E0E0"),
              borderSize: radio?.image !== undefined ? "4px" : "0",
              borderRadius: "4px",
              margin: radio?.image !== undefined ? "0" : "0 0 0 -11px",
              padding: radio?.image !== undefined ? "13px" : "0px",
              width: item?.vertical ? "100%" : "50%",
              transition: "all 0.2s ease-in-out",
              '&:hover': checkedItem != radio?.value && {
                borderColor: alpha(theme.palette.primary.main, 0.4),
              },
              '& .MuiTypography-root': {
                my: "auto"
              },
            })}
          />
        ))}
      </RadioGroup>
      <FormHelperText
        error={formProps.touched?.[item?.id] &&
          Boolean(formProps.errors?.[item?.id])}
      >
        {formProps.touched?.[item?.id] && formProps.errors?.[item?.id]}
      </FormHelperText>
    </Stack>
  );
};

SelectForm.propTypes = {
  item: PropTypes.object,
  formProps: PropTypes.object,
};
