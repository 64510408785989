import { ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const SectionTitle = ({ image, title, subtitle }) => {
  return (
    <Stack gap={4}>
      {image && (
        <ListItemAvatar>
          <img
            src={image}
            alt={image}
            style={{ width: "64px", height: "64px", objectFit: "contain" }}
          />
        </ListItemAvatar>
      )}
      <Stack>
        <ListItem style={{ padding: 0 }}>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              variant: "h3",
              fontWeight: 700,
              marginBottom: subtitle ? "8px" : 0,
            }}
            secondaryTypographyProps={{
              fontWeight: 400,
              fontSize: "12px",
            }}
          />
        </ListItem>
        <Typography>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

SectionTitle.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
