import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const TextForm = ({ item, props }) => {
  const [menuItems, setMenuItems] = React.useState([]);
  const theme = useTheme();
  const darkMode = theme.palette.mode == "dark";

  React.useEffect(() => {
    item?.menuItem?.then((menus) => {
      setMenuItems(menus);
    });
  }, [item?.menuItem]);

  return (
    <React.Fragment>
      <TextField {...props} select={item?.type === "select"}>
        {menuItems.map((menu) => (
          <MenuItem value={menu.value ? menu.value : menu.string} key={menu.id}>
            {menu.string.length > 4
              ? menu.string
                  .toLowerCase()
                  .replace(/(?:^|\s|-)\w/g, (match) => match.toUpperCase())
              : menu.string}
          </MenuItem>
        ))}
      </TextField>
      <Stack
        sx={{
          display: item?.info ? "flex" : "none",
          bgcolor: darkMode
            ? "rgba(58, 58, 58, 0.5)"
            : alpha(theme.palette.primary.main, 0.2),
          p: "14px 20px",
          mt: "20px !important",
        }}
      >
        <Typography variant="body2" color={!darkMode && "secondary"}>
          {item?.info}
        </Typography>
      </Stack>
    </React.Fragment>
  );
};

TextForm.propTypes = {
  item: PropTypes.object,
  props: PropTypes.object,
};

export default TextForm;
