import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: undefined,
  severity: undefined,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      action?.payload &&
        Object.entries(action?.payload)?.forEach(([key, value]) => {
          if (value !== undefined) state[key] = value;
        });
    },
    resetAlert: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
