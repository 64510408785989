import supabase from "./supabase"

const salesCodeService = async (code) => {
    const { data } = await supabase
        .from('sales_code')
        .select()
        .eq('sales_code', code.toUpperCase())
    if (data.length === 0) {
        return "XX"
    } else {
        return data[0].sales_code
    }
}

export default salesCodeService