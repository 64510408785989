import React from "react";
import PropTypes from "prop-types";
import ModalCard from "../molecules/modalCard";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const Disclaimer = ({
  ableToClose,
  children,
  handleClose,
  open,
  data,
  buttonText,
  buttonFunction,
}) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoading(false);
    }
  }, [data]);

  return (
    <ModalCard open={open} handleClose={handleClose} ableToClose={ableToClose}  topTitle="Disclaimer">
      <Stack my={2} mb={4} minHeight="80px">
        {loading ? (
          <Stack height="80px" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack>
            <Typography variant="h6" align="center" fontWeight={600} mb={2}>
              {data?.title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          </Stack>
        )}
        {children}
      </Stack>
      <Button
        variant="contained"
        onClick={buttonFunction ? buttonFunction : handleClose}
      >
        {buttonText}
      </Button>
    </ModalCard>
  );
};

Disclaimer.propTypes = {
  ableToClose: PropTypes.bool,
  buttonFunction: PropTypes.func,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

export default Disclaimer;
