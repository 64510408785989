import React, { useEffect, useState } from "react";
import { Container, Stack, useTheme } from "@mui/material";
import { RegFormSection } from "./regForm";
import { OTPSection } from "./otp";
import { KTPSection } from "./ktp";
import { OcrFormSection } from "./ocrForm";
import { RiskFormSection } from "./riskForm";
import { RdnFormSection } from "./rdnForm";
import { JobFormSection } from "./jobForm";
import { AdditionalFormSection } from "./additionalForm";
import { StatementFormSection } from "./statementFormSection";
import { SignedFormSection } from "./signedFormSection";
import { KTPWithFaceSection } from "./ktpWithFace";
import { InitialSection } from "../../templates";
import { Navbar } from "../../molecules/navbar";
import {
  fotoKTP,
  fotoSelfieKTP,
  sendPict,
  hpsrowWhite,
  hpsrowColor,
  fotoKTPC,
  fotoSelfieKTPC,
  sendPictC,
} from "../../../assets";
import { setRegister } from "../../../redux/slices/registerSlice";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import salesCodeService from "../../../services/salesCodeService";
import { setAlert } from "../../../redux/slices/alertSlice";
import Disclaimer from "../../organisms/disclaimer";
import { getDisclaimerData } from "../../../models/staticData";
import { IdFormSection } from "./idForm";
import { checkRegData } from "../../../services/apiService";
import supabase from "../../../services/supabase";

export const RegisterPage = () => {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const regEmail = searchParams.get("email"); //get email from retrieved register entry

  const [activeStep, setActiveStep] = useState(0);
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const [disclaimerData, setDisclaimerData] = useState({});
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const dispatch = useDispatch();

  React.useEffect(() => {
    getDisclaimerData().then((data) => {
      setDisclaimerData(data[0]);
    });
  }, []);

  React.useEffect(() => {
    const verifySalesCode = async () => {
      if (code) {
        const verifiedCode = await salesCodeService(code);
        if (verifiedCode != "XX") {
          dispatch(setRegister({ salesCode: verifiedCode }));
        } else {
          dispatch(
            setAlert({ isOpen: true, message: "Kode Sales tidak ditemukan" })
          );
        }
      }
    };
    verifySalesCode();
  }, [code]);

  React.useEffect(() => {
    if (regEmail) {
      const getSavedRegData = async () => {
        const { data, error } = await supabase.auth.getSession();

        if (error) {
          dispatch(setAlert({ isOpen: true, message: error.message }));
        }

        const res = await checkRegData({
          email: regEmail,
        });

        const regDataBody = res.data.data;
        const newToken = data.session.access_token;

        dispatch(
          setRegister({
            ...regDataBody,
            endpointString: regDataBody.endpointString,
            newEntry: false,
            token: newToken,
          })
        );

        console.log(regDataBody.lastSavedSection + 1 || 3)

        setActiveStep(regDataBody.lastSavedSection + 1 || 3);
      };

      getSavedRegData();
    }
  }, [regEmail]);

  const handleDisclaimer = () => {
    setOpenDisclaimer(false);
  };

  const steps = [
    {
      label: "Welcome",
      content: (
        <InitialSection
          topTitle=" "
          image={darkMode ? hpsrowWhite : hpsrowColor}
          title="Buka Rekening Saham"
          subtitle="Miliki perusahaan favorit kamu sekarang!"
          buttonText="Buka Sekarang"
          imageWidth="200px"
        />
      ),
    },
    { label: "Daftar Akun Baru", content: <RegFormSection /> },
    { label: "Daftar Akun Baru", content: <OTPSection /> },
    {
      label: "Buat Akun",
      content: (
        <InitialSection
          topTitle=" "
          image={darkMode ? fotoKTP : fotoKTPC}
          title="Sekarang Foto KTP Kamu"
          subtitle="KTP diwajibkan untuk pembukaan rekening saham
          atas peraturan OJK."
          buttonText="Berikutnya"
          imageWidth="250px"
        />
      ),
    },
    { label: "Buat Akun", content: <KTPSection /> },
    {
      label: "Buat Akun",
      content: (
        <InitialSection
          topTitle=" "
          image={darkMode ? fotoSelfieKTP : fotoSelfieKTPC}
          title="Sekarang Foto Selfie dengan KTP Kamu"
          subtitle="Kamu perlu foto selfie dengan KTP kamu untuk verifikasi
          kebenaran identitas kamu."
          buttonText="Ambil Foto"
          imageWidth="250px"
        />
      ),
    },
    { label: "Buat Akun", content: <KTPWithFaceSection /> },
    { label: "Buat Akun", content: <OcrFormSection /> },
    { label: "Buat Akun", content: <IdFormSection /> },
    { label: "Buat Akun", content: <JobFormSection /> },
    { label: "Buat Akun", content: <RiskFormSection /> },
    { label: "Buat Akun", content: <RdnFormSection /> },
    { label: "Buat Akun", content: <AdditionalFormSection /> },
    { label: "Buat Akun", content: <StatementFormSection /> },
    { label: "Buat Akun", content: <SignedFormSection /> },
    {
      label: "Buat Akun",
      content: (
        <InitialSection
          image={darkMode ? sendPict : sendPictC}
          topTitle=" "
          title="Registrasi Kamu Segera  Diproses"
          subtitle="Terimakasih. Permintaan kamu sedang 
          dalam proses verifikasi oleh tim kami"
          buttonText="Selesai"
        />
      ),
    },
  ].map((step, index) => ({
    ...step,
    content: React.cloneElement(step?.content, {
      activeStep: activeStep,
      onClickNext: () =>
        setActiveStep(
          index === steps?.length - 1 ? steps?.length - 1 : index + 1
        ),
    }),
  }));

  useEffect(() => {
    // the handler for showing the prompt if the user tries to close the window
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  return (
    <Container
      maxWidth="xs"
      disableGutters
      sx={{
        bgcolor: darkMode ? "background.default" : "background.paper",
        minHeight: "100vh",
      }}
    >
      <Disclaimer
        open={openDisclaimer}
        handleClose={handleDisclaimer}
        data={disclaimerData}
        ableToClose={false}
        buttonText="Setuju"
      />
      <Stack
        sx={{
          minHeight: "90vh",
        }}
      >
        {activeStep !== 0 && (
          <Navbar
            progress={(activeStep / (steps?.length - 1)) * 100}
            OnBack={setActiveStep}
            pageNow={activeStep}
            label={steps?.[activeStep]?.label}
          />
        )}
        <Stack
          spacing="32px"
          sx={{
            flexGrow: 1,
            height: "100%",
            padding: "32px 24px",
          }}
        >
          {steps?.[activeStep]?.content}
        </Stack>
      </Stack>
    </Container>
  );
};
