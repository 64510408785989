import axios from "axios";
import imageCompression from "browser-image-compression";
import moment from "moment";
import { agamaData, jenisKelaminData, kewarganegaraanData, pernikahanData } from "../models/staticData";

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const splitBirthPlaceBirthday = (ocrData) => {
  if (
    ocrData?.birthPlaceBirthday &&
    !ocrData?.placeOfBirth &&
    !ocrData?.birthday
  ) {
    const birthPlaceBirthday = ocrData?.birthPlaceBirthday.split(", ");
    const placeOfBirth = birthPlaceBirthday[0];
    const birthday = moment(birthPlaceBirthday[1], "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    return { ...ocrData, placeOfBirth, birthday };
  } else return ocrData;
};

const combineAddress = (ocrData, comparedData) => {
  const address = `${ocrData?.address} ${ocrData?.rtrw || ''} ${comparedData?.village || ''} ${comparedData?.district || ''} ${comparedData.city || ''} ${comparedData?.province || ''}`;
  return { address: address };
}

const compressImage = async (inputUri) => {
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 4096,
    fileType: "image/jpeg",
    useWebWorker: true,
  };

  const imageFile = dataURLtoFile(inputUri, "compressed.png");
  const compressedBlob = await imageCompression(imageFile, options);
  const compressedUri = await imageCompression.getDataUrlFromFile(compressedBlob);

  return { compressedBlob, compressedUri };
};

const postIdCardImg = async (imgData) => {
  const { data } = await axios.post(process.env.REACT_APP_OCR_URL,
    { image: imgData }
  )
  return data;
};

const compareIdCardAndFace = async (firstImage, secondImage) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { data } = await axios.post(`${apiUrl}api/v1/gemini/compare`, {
    firstImage,
    secondImage,
  });
  return data;
};

const stringToValue = async (staticString) => {
  const [agamaDataArr, pernikahanDataArr, kewarganegaraanDataArr, jenisKelaminArr] = await Promise.all([
    agamaData,
    pernikahanData,
    kewarganegaraanData,
    jenisKelaminData,
  ]);

  const jenisKelamin = jenisKelaminArr.find((item) => item.string === staticString);
  if (jenisKelamin) return jenisKelamin.value;

  const agama = agamaDataArr.find((item) => item.string === staticString);
  if (agama) return agama.value;

  const pernikahan = pernikahanDataArr.find((item) => item.string === staticString);
  if (pernikahan) return pernikahan.value;

  const kewarganegaraan = kewarganegaraanDataArr.find((item) => item.string === staticString);
  if (kewarganegaraan) return kewarganegaraan.value

  return staticString;
}

export const ocrService = {
  compressImage,
  postIdCardImg,
  dataURLtoFile,
  compareIdCardAndFace,
  splitBirthPlaceBirthday,
  combineAddress,
  stringToValue,
};
