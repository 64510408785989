import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alertSlice";
import registerSlice from "./slices/registerSlice";

export const store = configureStore({
  reducer: {
    register: registerSlice,
    alert: alertSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});
