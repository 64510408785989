import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  endpointString: "",
  salesCode: "",
  email: "",
  phoneNumber: "",
  idNumber: "",
  name: "",
  bloodType: "",
  religion: "",
  gender: "",
  birthPlaceBirthday: "",
  province: "",
  city: "",
  district: "",
  village: "",
  rtrw: "",
  occupation: "",
  expiryDate: "",
  nationality: 1,
  maritalStatus: "",
  address: "",
  postalCodeId: "",
  placeOfBirth: "",
  birthday: "",
  bankName: "",
  account: "",
  numberAccount: "",
  npwp: "",
  mothersName: "",
  lastEducation: "",
  incomeTotalInYear: "",
  incomeSource: "",
  investGoal: "",
  rdnBank: "",
  verifBank: false,
  bankNpwp: "",
  bankAgreement: false,
  jobs: null,
  institution: "",
  businessField: "",
  position: "",
  institutionAddress: "",
  institutionCity: "",
  postalCode: "",
  haveFamilyInHPS: 2,
  haveFamilyInStockExchange: 2,
  instruksiAS: 1,
  customerSign: "",
  photoKTP: "",
  photoSelfie: "",
  namePOT: "",
  relationshipPOT: "",
  jobsPOT: "",
  businessFieldPOT: "",
  positionPOT: "",
  institutionPOT: "",
  institutionPhonePOT: "",
  agreement: false,
  POTincomeTotalInYear: "",
  spouseName: "",
  token: "",
  newEntry: true,
};

export const kycSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegister: (state, action) => {
      action?.payload &&
        Object.entries(action?.payload)?.forEach(([key, value]) => {
          if (value !== undefined) state[key] = value;
        });
    },
    resetRegister: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setRegister, resetRegister } = kycSlice.actions;

export default kycSlice.reducer;
