import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ModalCard = ({ topTitle, children, open, handleClose, ableToClose }) => {
  return (
    <Modal open={open} onClose={ableToClose ? handleClose : null}>
      <Paper
        elevation={0}
        component={Stack}
        variant="outlined"
        sx={{
          width: "90vw",
          maxWidth: "320px",
          p: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          outline: "none",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">{topTitle}</Typography>
          {ableToClose && (
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          )}
        </Stack>
        <Divider />
        {children}
      </Paper>
    </Modal>
  );
};

ModalCard.propTypes = {
  ableToClose: PropTypes.bool,
  buttonFunction: PropTypes.func,
  topTitle: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ModalCard;
