import React, { useState } from "react";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setRegister } from "../../../redux/slices/registerSlice";
import { POTriskFormModel, riskFormModel } from "../../../models";
import { FormTemplate } from "../../templates";
import { yupSchemaGenerator } from "../../../services/yupSchemaGenerator";
import { saveRegData } from "../../../services/apiService";

export const RiskFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.register);
  const [isLoading, setIsLoading] = useState(false);

  const isPOT = regData.jobs === 6 || regData.jobs === 7;

  const validationSchema = yup.object(
    yupSchemaGenerator(isPOT ? POTriskFormModel : riskFormModel)
  );

  const formProps = useFormik({
    validationSchema: validationSchema,
    initialValues: regData,
    onSubmit: async (values) => {
      dispatch(setRegister(values));
      await saveRegData(values, regData.token, regData.newEntry);
      setIsLoading(true);
      // TODO: SUBMIT DATA TO BACKEND
      setIsLoading(false);

      onClickNext();
    },
  });

  return (
    <FormTemplate
      formProps={formProps}
      isLoading={isLoading}
      formModel={isPOT ? POTriskFormModel : riskFormModel}
      badge={<GppMaybeIcon style={{ width: "40px", height: "40px" }} />}
      title="Profile Risiko"
      subtitle="Data ini untuk mengetahui level resiko kamu."
      buttonText="Berikutnya"
    />
  );
};

RiskFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
