import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const InitialSection = ({
  topTitle,
  image,
  title,
  subtitle,
  onClickNext,
  buttonText,
  imageWidth
}) => {
  return (
    <Stack gap="36px" style={{
      flexGrow: 1,
      height: "100%"
    }}>
      <Stack
        py={2}
        style={{ flexGrow: 1, alignItems: "center", justifyContent: "space-between" }}
      >
        {topTitle && (
          <Typography
            style={{ fontWeight: 600, fontSize: "36px", textAlign: "center" }}
          >
            {topTitle}
          </Typography>
        )}

        {image && (
          <img
            src={image}
            alt={image}
            style={{ width: imageWidth }}
          />
        )}

        <Stack gap="16px" style={{ maxWidth: "300px" }}>
          {title && (
            <Typography variant="h3" textAlign="center"
            >
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography
              style={{ fontWeight: 500, fontSize: "14px", textAlign: "center" }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Button
        variant="contained"
        disableElevation
        style={{ textTransform: "none", width: "100%" }}
        onClick={onClickNext}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

InitialSection.defaultProps = {
  imageWidth: "150px"
}

InitialSection.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  topTitle: PropTypes.string,
  onClickNext: PropTypes.func,
  buttonText: PropTypes.string,
  imageWidth: PropTypes.string
};
