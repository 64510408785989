/* eslint-disable no-undef */

//png
export const keyIcon = require("./keyIcon.png");
export const keyIconC = require("./keyIconC.png");
export const phoneIcon = require("./phoneIcon.png");
export const phoneIconC = require("./phoneIconC.png");
export const fotoKTP = require("./fotoKTP.png");
export const fotoKTPC = require("./fotoKTPC.png");
export const fotoSelfieKTP = require("./fotoSelfieKTP.png");
export const fotoSelfieKTPC = require("./fotoSelfieKTPC.png");
export const bankBCA = require("./bankBCA.png");
export const bankBCAC = require("./bankBCAC.png");
export const sendPict = require("./sendPict.png");
export const sendPictC = require("./sendPictC.png");
export const hpsrowWhite = require("./hpsrow.png");
export const hpsrowColor = require("./hpsrowColor.png");
