/* eslint-disable react/no-children-prop */
import ModeIcon from "@mui/icons-material/Mode";
import CachedIcon from "@mui/icons-material/Cached";
import React, { useState, createRef } from "react";
import { Stack, Button, Typography, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { PromptCard } from "../../molecules";
import { setRegister } from "../../../redux/slices/registerSlice";
import { useFormik } from "formik";
import { ReactSketchCanvas } from "react-sketch-canvas";
import {
  api,
  apiUrls,
  saveAllData,
  saveRegData,
  uploadPhoto,
} from "../../../services/apiService";
import { setAlert } from "../../../redux/slices/alertSlice";

export const SignedFormSection = ({ onClickNext }) => {
  const dispatch = useDispatch();
  const canvas = createRef(null);
  const regData = useSelector((state) => state.register);
  const [isLoading, setIsLoading] = useState(false);
  const [checkStrokes, setCheckStrokes] = useState(false);
  const [error, setError] = useState(false);

  const uploadAll = async (data) => {
    const response = await api.post(
      apiUrls.saveAll + regData.endpointString,
      data,
      { headers: { "Content-Type": "text/plain" } }
    );
    try {
      if (response?.data?.success) {
        onClickNext();
      }
    } catch (error) {
      dispatch(setAlert({ message: "Error uploading data", isOpen: true }));
    }
  };

  const formProps = useFormik({
    initialValues: regData,
    onSubmit: async () => {
      setIsLoading(true);
      if (checkStrokes) {
        try {
          const data = await canvas.current.exportImage("png");
          const resSign = await uploadPhoto(
            data,
            "uploadSignature",
            regData.endpointString,
            apiUrls.uploadSignature
          );
          const signPath = { customerSign: resSign.data.file_name };
          const uploadAllData = { ...regData, ...signPath };
          dispatch(setRegister(signPath));
          await saveRegData(signPath, regData.token, regData.newEntry);
          uploadAll(saveAllData(uploadAllData));
        } catch (error) {
          dispatch(setAlert({ message: "Error uploading data", isOpen: true }));
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <Stack
      spacing="32px"
      justifyContent="space-between"
      style={{ height: "100%" }}
    >
      <Stack spacing="32px">
        <PromptCard
          icon={<ModeIcon style={{ width: "40px", height: "40px" }} />}
          title="Spesimen Tanda Tangan"
          subtitle="Silahkan tanda tangani di area kotak yang sudah kami berikan"
        />
        <Typography
          style={{ fontWeight: 700, fontSize: "14px", textAlign: "center" }}
        >
          Pastikan tanda tangan kamu semirip mungkin dengan yang ada di KTP kamu
          supaya tidak ditolak.
        </Typography>
        <Stack gap="3px">
          <ReactSketchCanvas
            ref={canvas}
            strokeWidth={2}
            width="40%"
            id="customerSign"
            name="customerSign"
            height="100%"
            strokeColor="black"
            canvasColor="white"
            style={{
              margin: "auto",
              cursor: "crosshair",
              width: "100%",
              height: "50vh",
              border: `1px dashed ${error ? "#ff1744" : "#515151"}`,
              borderRadius: "8px",
              overflow: "hidden",
            }}
            onStroke={() => {
              setCheckStrokes(true);
              setError(false);
            }}
          />
          {error && (
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                color: "#ff1744",
              }}
            >
              Tanda tangan kosong
            </Typography>
          )}
        </Stack>
        <Button
          type="submit"
          variant="outlined"
          disableElevation
          style={{
            textTransform: "none",
            width: "fit-content",
            gap: "10px",
            fontSize: "16px",
          }}
          onClick={() => {
            canvas.current.clearCanvas();
            setError(true);
          }}
        >
          <CachedIcon style={{ fontSize: "22px" }} />
          Ulangi
        </Button>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        disableElevation
        style={{ textTransform: "none" }}
        disabled={isLoading}
        onClick={() => {
          checkStrokes ? formProps?.handleSubmit() : setError(true);
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Berikutnya"}
      </Button>
    </Stack>
  );
};

SignedFormSection.propTypes = {
  onClickNext: PropTypes.func,
};
