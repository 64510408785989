import { Container } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Camera, FACING_MODES } from "../organisms";

export const CameraPage = ({
  frame,
  onClick,
  onClose,
  type,
  facingMode,
}) => {

  const checkFacingMode = () => {
    if (facingMode === "rear") {
      return FACING_MODES.ENVIRONMENT;
    } else if (facingMode === "front") {
      return FACING_MODES.USER;
    } else {
      return FACING_MODES.USER;
    }
  }

  return (
    <div
      style={{
        zIndex: 10,
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        width: "100vw",
        height: "100%",
        backgroundColor: "black",
      }}
    >
      <Container maxWidth="xs" style={{ height: "100%", padding: 0 }}>
        <Camera
          isFullscreen
          frame={frame}
          idealFacingMode={checkFacingMode()}
          onTakePhoto={onClick}
          onTakePhotoAnimationDone={onClose}
          type={type}
        />
      </Container>
    </div>
  );
};

CameraPage.propTypes = {
  frame: PropTypes.func,
  facingMode: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.string,
};
