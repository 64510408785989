import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  Link,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";

const CheckboxForm = ({ item, formProps, errorText, rest }) => {
  const [agreementData, setAgreementData] = React.useState([]);

  React.useEffect(() => {
    item?.link?.then((data) => {
      setAgreementData(data[0]);
    });
  }, []);

  const agreementLinkProps = {
    target: "_blank",
    rel: "noopener noreferrer",
  };

  const labelWithLink =
    item?.link && agreementData?.text?.replace(agreementData.url_text, " ");

  const checkFormProps = {
    ...rest,
    label: item?.link ? (
      <>
        {labelWithLink}{" "}
        <Link {...{ href: agreementData.file, ...agreementLinkProps }}>
          {agreementData.url_text}
        </Link>
      </>
    ) : (
      item?.label
    ),
  };

  return (
    <Stack>
      <FormControlLabel
        control={
          <Checkbox
            onClick={() =>
              item?.link &&
              !formProps.values?.[item?.id] &&
              window.open(agreementData.url, "_blank")
            }
            checked={formProps.values?.[item?.id]}
          />
        }
        {...checkFormProps}
        sx={{
          alignItems: "flex-start",
          m: 0,
          "& .MuiCheckbox-root": {
            p: 0,
            pt: 0.5,
            pr: 2,
          },
          "& .MuiTypography-root": {
            my: "auto",
            fontSize: "14px",
          },
        }}
      />
      <FormHelperText {...rest}>{errorText}</FormHelperText>
    </Stack>
  );
};

export default CheckboxForm;

CheckboxForm.propTypes = {
  item: PropTypes.object,
  formProps: PropTypes.object,
  errorText: PropTypes.string,
  rest: PropTypes.object,
};
