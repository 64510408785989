import supabase from "../services/supabase";

const getPendidikanData = async () => {
  const { data, error } = await supabase
    .from("pendidikan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getAgamaData = async () => {
  const { data, error } = await supabase
    .from("agama")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getJumlahPenghasilanData = async () => {
  const { data, error } = await supabase
    .from("jumlah_penghasilan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getKewarganegaraanData = async () => {
  const { data, error } = await supabase
    .from("kewarganegaraan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getPekerjaanData = async () => {
  const { data, error } = await supabase
    .from("pekerjaan")
    .select("id, string, value")
    .order("string", { ascending: true })
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getPekerjaanPOTData = async () => {
  const { data, error } = await supabase
    .from("pekerjaan_pot")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getPembukaanRekeningData = async () => {
  const { data, error } = await supabase
    .from("pembukaan_rekening")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getPernikahanData = async () => {
  const { data, error } = await supabase
    .from("pernikahan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getSeumberPenghasilanData = async () => {
  const { data, error } = await supabase
    .from("sumber_penghasilan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getBankData = async () => {
  const { data, error } = await supabase
    .from("bank")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

export const getRdnQuestionData = async (bank) => {
  const { data, error } = await supabase
    .from("rdn_pertanyaan")
    .select("id, bank, questions")
    .eq("bank", bank);
  if (error) {
    console.log(error);
  } else {
    return data[0];
  }
};

export const getStatementData = async (bank) => {
  const { data, error } = await supabase
    .from("rdn_statement")
    .select("id, bank, items, file, url_text")
    .eq("bank", bank);
  if (error) {
    console.log(error);
  } else {
    return data[0];
  }
};

const getBidangUsahaData = async () => {
  const { data, error } = await supabase
    .from("bidang_usaha")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getJabatanData = async () => {
  const { data, error } = await supabase
    .from("jabatan")
    .select("id, string, value");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getCityData = async () => {
  const { data, error } = await supabase
    .from("kota")
    .select("id, provinsi(provinsi), kota");
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

export const getDistrictData = async (kota) => {
  const { data, error } = await supabase
    .from("kecamatan")
    .select("id, kota!inner(*), kecamatan")
    .eq("kota.kota", kota);
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

export const getVillageData = async (kecamatan) => {
  const { data, error } = await supabase
    .from("kelurahan")
    .select("id, kecamatan!inner(*), kelurahan")
    .eq("kecamatan.kecamatan", kecamatan);
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

export const getDisclaimerData = async () => {
  const { data, error } = await supabase.from("disclaimer").select();
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getHubunganData = async () => {
  const { data, error } = await supabase.from("hubungan").select();
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getJenisKelaminData = async () => {
  const { data, error } = await supabase.from("jenis_kelamin").select();
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

const getAgreementData = async () => {
  const { data, error } = await supabase.from("agreement").select();
  if (error) {
    console.log(error);
  } else {
    return data;
  }
};

export const pendidikanData = getPendidikanData();
export const agamaData = getAgamaData();
export const jumlahPenghasilanData = getJumlahPenghasilanData();
export const kewarganegaraanData = getKewarganegaraanData();
export const pekerjaanData = getPekerjaanData();
export const pekerjaanPOTData = getPekerjaanPOTData();
export const pembukaanRekeningData = getPembukaanRekeningData();
export const pernikahanData = getPernikahanData();
export const sumberPenghasilanData = getSeumberPenghasilanData();
export const bankData = getBankData();
export const bidangUsahaData = getBidangUsahaData();
export const jabatanData = getJabatanData();
export const cityData = getCityData();
export const districtData = getDistrictData();
export const hubunganData = getHubunganData();
export const jenisKelaminData = getJenisKelaminData();
export const agreementData = getAgreementData();
