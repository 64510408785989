import supabase from "./supabase"

const compareArea = async (ocrData) => {
    const { data, error } = await supabase
        .from("kelurahan")
        .select("id, provinsi(provinsi), kota(kota), kecamatan(kecamatan), kelurahan, kode_pos")
        .ilike("kelurahan", `%${ocrData.village}%`)
        .ilike("kecamatan.kecamatan", `%${ocrData.district}%`)
    if (!error) {
        const matchedEntry = data.map(item => {
            if (item.kecamatan)
                return {
                    ...item,
                    kecamatan: item.kecamatan.kecamatan,
                    kota: item.kota.kota,
                    provinsi: item.provinsi.provinsi
                }
        }).filter((item) => item !== undefined)[0]
        if (matchedEntry) {
            console.log(matchedEntry)
            return {
                village: matchedEntry.kelurahan,
                district: matchedEntry.kecamatan,
                city: matchedEntry.kota,
                province: matchedEntry.provinsi,
            }
        } else {
            return {
                village: "",
                district: "",
                city: "",
                province: "",
            }
        }
    } else {
        console.log(error)
    }
}


export default compareArea