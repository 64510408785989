import { Stack } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import PropTypes from "prop-types";
import { SelectForm } from "../molecules";
import SelectSearchForm from "../molecules/selectSearchForm";
import TextForm from "../molecules/textForm";
import CheckboxForm from "../molecules/checkboxForm";

export const FormFields = ({ formProps, formItems }) => {
  return (
    <Stack spacing="32px">
      {/* FORM */}
      {formItems?.map((item) => {
        const errorText =
          formProps.touched?.[item?.id] && formProps.errors?.[item?.id];
        const props = {
          variant: "standard",
          id: item?.id,
          name: item?.id,
          type: item?.type,
          label: item?.label,
          value: formProps.values?.[item?.id],
          onChange: formProps.handleChange,
          onKeyDown: formProps.handleChange,
          placeholder: item?.placeholder,
          error:
            formProps.touched?.[item?.id] &&
            Boolean(formProps.errors?.[item?.id]),
          helperText: errorText,
          InputLabelProps: { shrink: true },
        };
        const { InputLabelProps, helperText, ...rest } = props;
        const conditionalField =
          item?.condition && item?.conditionFunction(formProps);
        const renderField = () => {
          if (item?.type === "phone") {
            return (
              <MuiPhoneNumber key={item?.id} {...props} defaultCountry="id" />
            );
          } else if (item?.type === "radio") {
            return (
              <SelectForm key={item?.id} item={item} formProps={formProps} />
            );
          } else if (item?.type === "checkbox") {
            return (
              <CheckboxForm
                key={item?.id}
                item={item}
                formProps={formProps}
                errorText={errorText}
                rest={rest}
              />
            );
          } else if (item?.type === "search") {
            return (
              <SelectSearchForm
                key={item?.id}
                item={item}
                formProps={formProps}
                props={props}
              />
            );
          } else {
            return <TextForm key={item?.id} item={item} props={props} />;
          }
        };
        if (!item.condition) {
          return renderField();
        } else if (conditionalField) {
          return renderField();
        }
      })}
    </Stack>
  );
};

FormFields.propTypes = {
  formProps: PropTypes.object,
  formItems: PropTypes.arrayOf(PropTypes.object),
  InputLabelProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
  helperText: PropTypes.string,
};
