import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import "./assets/fonts/TTCommons/stylesheet.css";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: { main: "#3AAFDC" },
    secondary: { main: "#03559A" },
    error: { main: red.A400 },
  },
  typography: { fontFamily: "TT Commons" },
});

export default theme;
